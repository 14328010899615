<template>
  <div class="guide">
    <Normaltop
      :url="qiniuUrl"
      title="报考指南"
      en="Application Guide"
    ></Normaltop>
    <div class="guide-cont">
      <div class="part">
        <h2 class="part-title text-center">考试大纲</h2>
        <p class="part-cont">
          为方便报考者充分了解网培师的评测考试要求，特制定本纲。
        </p>

        <h3 class="part-h3">一、考试方式</h3>
        <p class="part-cont">
          1.网培师的三个评测模块考试均使用在线答题的考试方式，试卷为闭卷形式；<br />
          2.考试试题全部为客观题，报考者只需要在规定时间内回答完试题，并满足规定的正确率即为通过；<br />
          3.<span class="font_red"
            >报考者需在一天内参加所有模块并完成评测，请报考者安排好时间准时参加考试。</span
          >
        </p>
        <h3 class="part-h3">二、作答要求</h3>
        <p class="part-cont">
          1.由于考试为在线答题的方式，所以报考者无需携带文具，只要使用配带摄像头的电脑参加考试即可；<br />
          2.参加考试时系统将使用面部识别方式验证考生是否为报考者本人，考生在答题时需将面部保持在摄像头有效区域，远程智能监考系统会对比考生是否为本人；<br />
          3.考生在答题过程中不得以任何方式向第三方求助，且不可中途离场，不可有其他无关人员进入摄像头区域；<br />
          4.<span class="font_red"
            >如在阅卷时发现考生有违规现象，直接以作弊处理，本次所有评测成绩作废，如发现2次作弊行为，平台将直接取消考生考试资格并不予退款！</span
          >
        </p>
        <h3 class="part-h3">三、考试规则</h3>
        <p class="part-cont">
          1.考生凭本人账号和密码登录“网培师官网”进入在线考试系统，通过人脸识别确认考生本人身份后，方可开始考试；<br />
          2.考试时，须全程保持人脸在人脸识别窗口范围内，且免冠正面面向屏幕；<br />
          3.未提交答卷之前，不得离开考位；<br />
          4.考试过程中请勿随意讲话；<br />
          5.考试过程中，考生周边近距离内不得出现其他人员；<br />
          6.不得中途换他人替考；<br />
          7.不得使用虚拟摄像头、虚拟机；<br />
          8.不得拔掉摄像头；<br />
          9.不得用照片或录像替考；<br />
          10.不得外接显示器；<br />
          11.不得使用网络搜索和远程桌面控制；<br />
          12.不得做与考试无关的事情；<br />
          13.衣着不整者不得参加考试；<br />
          14.考生应认真独立答题，不得利用通讯工具与外界联系，不得与他人交谈；<br />
        </p>
        <h3 class="part-h3">四、颁证条件</h3>
        <p class="part-cont">
          1.评测完成后，平台会根据考生成绩颁发相应证书，证书获取条件详情见下表格；<br />
          2.当前两项及格时可获得初级网培师资格证书；<br />
          3.三项都及格时可获得中级网培师资格证书；<br />
          4.申请高级网培师的学员需上传视频课件和辅助资料，并且自身满足一些条件才能通过专家评审，详情见“报考指南-报考须知”页面。<br />
        </p>
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          :header-cell-style="{ color: '#960f23', borderColor: '#ccc' }"
          :span-method="objectSpanMethod"
          center
          :cell-style="cellStyle"
        >
          <el-table-column prop="result" label="" width="180">
          </el-table-column>
          <el-table-column prop="grade1" label="教学综合能力评测" width="180">
          </el-table-column>
          <el-table-column prop="grade2" label="网培政策规范评测" width="180">
          </el-table-column>
          <el-table-column prop="grade3" label="教学数字化能力评测">
          </el-table-column>
          <el-table-column prop="certs" label="颁发证书"></el-table-column>
        </el-table>
        <h3 class="part-h3">五、补考规则</h3>
        <p class="part-cont">
          1.<span class="font_red"
            >报考者在每个评测模块都拥有一次补考机会，在评测结果出来后，不及格的科目需要在后续的考试开放时间内重新考试；<br
          /></span>
          2.<span class="font_red"
            >初级和中级网培师资格证书评测，评测结果达到拿证条件的，平台颁发相应资格证书，当本次考试有不及格的科目，报考者则需要在一个月内将不及格的模块补考完毕，经补考后成绩仍不满足拿证条件的，则需要重新缴费参加考试；<br
          /></span>
          3.<span class="font_red"
            >如果考生未在评测后的一个月内完成补考，平台将清除补考机会！</span
          >
        </p>
        <h3 class="part-h3">六、考试内容</h3>
        <p class="part-cont">
          网培师考试主要测试报考者报考网培师应当注意的基本素质和能力，包括教学综合能力、网培政策规范性能力和教学数字化能力三个方面。
        </p>
        <p class="part-cont">
          （一）教学综合能力评测。主要考察报考者对教育学和与教育相关的心理学的认知水平。考试范围包括：
        </p>
        <p class="part-cont">
          教学基本能力<br />
          1.教育学基本知识<br />
          2.理解国家实施素质教育的基本要求<br />
          3.掌握在教育中开展素质教育的途径和方法<br />
          4.具有较高的文化素养<br /><br />
          职业道德<br />
          1.教师的职业道德规范<br />
          2.教育相关法律法规<br />
          3.教师的职业行为规范以及需要处理的主要关系<br />
          <br />
          教育心理学<br />
          1.学生心理<br />
          2.教师心理<br />
          3.学习心理<br />
          4.教学心理<br />
        </p>
        <p class="part-cont">
          （二）网培政策规范评测。主要考察报考者掌握网络教学、网络安全、知识产权、网上行为文明规范等有关政策法规，及运用相关知识分析与解决问题的能力。考试涉及范围包括：
        </p>
        <p class="part-cont">
          《中华人民共和国保守国家秘密法》<br />
          1.中华人民共和国保守国家秘密法主要内容<br />
          2.相关案例 <br /><br />《中华人民共和国著作权法》<br />
          1.中华人民共和国著作权法主要内容<br />
          2.相关案例<br /><br />
          《中华人民共和国计算机信息系统安全保护条例》<br />
          1.中华人民共和国计算机信息系统安全保护条例主要内容
          <br />2.相关案例<br /><br />
          《计算机软件保护条例》<br />
          1.计算机软件保护条例主要内容 <br />2.相关案例<br /><br />
          《互联网电子公告服务管理规定》<br />
          1.互联网电子公告服务管理规定主要内容<br />
          2.相关案例 <br /><br />《信息网络传播权保护条例》<br />
          1.信息网络传播权保护条例主要内容<br />
          2.相关案例<br /><br />
          《中华人民共和国网络安全法》<br />
          1.中华人民共和国网络安全法主要内容<br />
          2.相关案例 <br /><br />
          《互联网直播服务管理规定》<br />
          1.互联网直播服务管理规定主要内容 <br />2.相关案例<br /><br />
          其他有关计算机及互联网规定的法律、法规。<br /><br />
        </p>
        <p class="part-cont">
          （三）教学数字化能力评测。主要考察报考者在网络培训过程中，在线教学能力、正确使用数字化工具的能力。<br />
          本评测模块试题分两大部分，“在线辅导教学”和“数字教学工具”，其中在线教学包括“在线辅导教学”、“在线直播课”以及“在线录播课”，数字教学工具涉及包括金山WPS演示、微软PowerPoint、Adobe
          Photoshop，以及Premiere Pro、Camtasia
          Studio、会声会影等辅助软件工具。<br />
          (1)在线教学（占40%）<br />
          1.在线辅导教学<br />
          2.在线直播课<br />
          3.在线录播课<br />
          4.网络教学的控制与保障<br />
          <br />
          (2)金山WPS演示及Microsoft PowerPoint 软件（占30%）<br />
          1.构建清晰易懂的演示结构<br />
          2.制作图文并茂的幻灯片><br />
          <br />
          (3)Adobe Photoshop 软件 （占10%）<br />
          1.Photoshop软件基本功能<br />
          2.Photoshop操作方法<br /><br />
          (4)）视频剪辑软件 (占20%),在Premiere、Camtasia
          Studio、会声会影中任选一个：<br />
          Adobe Premiere Pro <br />1.影视编辑基本概念<br />
          2.Adobe Premiere Pro工作流程<br />
          3.Adobe Premiere Pro基本操作方法<br /><br />
          Camtasia Studio<br />
          1.Camtasia Studio 基本功能<br />
          2.Camtasia Studio 基本操作使用方法<br /><br />

          会声会影 <br />1.软件特点<br />
          2.主要使用方法和注意事项<br /><br />
          <!-- (4)网络及数字化应用方面的常识性知识，占10%<br />
          1、网络、云计算、云存储、移动互联网等名词解释<br />
          2、网络教学/辅导中基本设备的使用常识<br /><br /> -->

          <!-- (4)视频课件制作实操：按要求制作和上传一段本人制作的视频课件，由平台组织专家评分。评分重点考核：<br />
          1.课件教学设计思路<br />
          2.音视频画面播放效果<br />
          3.内容条理清晰<br />
          4.内容设计的创新性<br />
          5.趣味性<br /> -->
        </p>

        <h3 class="part-h3">七、题型介绍</h3>
        <p class="part-cont">
          例1.关键信息基础设施的运营者采购网络产品和服务，
          可能影响国家安全的，应当通过国家网信部门会同国务院有关部门组织的（）。<br />
          A.国家网络审查<br />
          B.国家网信安全审查<br />
          C.国家采购审查 <br />D.国家安全审查<br />
          正确答案：D<br /><br />
          例2.李教授应邀讲授“商标法新修改内容的学理解读及赔偿责任条款的适用”，在直播前根据自己对新商标法的研究及形成的观点，写好内容文字，备好课。直播时，李教授口头讲出来的即其提前准备好的讲稿，请问李教授对讲稿内容的直播呈现享有的权力是（）
          <br />A.文字作品的著作权 <br />B.表演者权<br />
          C.复制权<br />
          D.发表权 <br />正确答案：B <br /><br />例3.Microsoft
          PowerPoint窗口中，在下拉菜单里，一般不属于菜单栏的是（）
          <br />A.编辑<br />B.视图<br />C.程序<br />
          D.格式<br />
          正确答案：C
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Normaltop from '../components/normaltop'
export default {
  name: 'Syllabus',
  components: { Normaltop },
  data() {
    return {
      qiniuUrl: this.$qiniuUrl + 'guide/banner.png',
      tableData: [
        {
          result: '评测结果',
          grade1: '及格',
          grade2: '不及格',
          grade3: '不及格',
          certs: '无',
        },
        {
          result: '评测结果',
          grade1: '及格',
          grade2: '及格',
          grade3: '不及格',
          certs: '初级网培师资格证书',
        },
        {
          result: '评测结果',
          grade1: '及格',
          grade2: '及格',
          grade3: '及格',
          certs: '中级网培师资格证书',
        },
      ],
    }
  },
  created() {},
  methods: {
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 3 === 0) {
          return {
            rowspan: 3,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    },
    cellStyle() {
      return 'border-color:#ccc!important;'
    },
  },
}
</script>
<style lang="less" scoped>
.guide {
  font-weight: normal;
  font-size: 16px;
  img {
    width: 100%;
  }
  /deep/.el-button--danger {
    background: #e10133;
    width: 150px;
    color: #fff;
  }
  .guide-cont {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding: 70px 0;
    .part {
      width: 60%;
      min-width: 1100px;
      margin: 0 auto;
      position: relative;
      padding-top: 50px;
      .methods {
        padding: 25px 0;
      }
      .part-title {
        color: #000005;
        font-weight: bold;
        font-size: 36px;
        padding-bottom: 50px;
      }
      .method-name {
        color: #ce9a6c;
        padding-top: 10px;
      }
      .part-h3 {
        font-size: 22px;
        padding: 25px 0 10px 0;
      }
      .part-cont {
        color: #333;
        line-height: 2;
      }
      .part-indent {
        text-indent: 20px;
      }

      .icon {
        width: 120px;
        height: 120px;
        margin-right: 30px;
      }
    }
  }
}
/deep/.el-table th > .cell {
  text-align: center;
}

/deep/.el-table .cell {
  text-align: center;
}
.el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
  border-color: #ccc;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #ccc;
}
/deep/.el-table tr:nth-child(1) td:nth-child(3) {
  color: red;
}
/deep/.el-table tr:nth-child(1) td:nth-child(4) {
  color: red;
}
/deep/.el-table tr:nth-child(2) td:nth-child(3) {
  color: red;
}
/deep/.el-table td:nth-child(2) {
  color: #005efb;
}
/deep/.el-table tr:nth-child(2) td:nth-child(1) {
  color: #005efb;
}
/deep/.el-table tr:nth-child(3) td:nth-child(1) {
  color: #005efb;
}
/deep/.el-table tr:nth-child(3) td:nth-child(3) {
  color: #005efb;
}
</style>
